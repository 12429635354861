import React from "react";
import { EmailAddress } from "../data/NavsData";

export default function Email() {
  return (
    <div className="aj-email">
      <a
        href={`mailto:${EmailAddress}`}
        target="_blank"
        rel="noopener noreferrer"
        className="link"
      >
        {EmailAddress}
      </a>
      <div className="line"></div>
    </div>
  );
}
