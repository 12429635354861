import React from "react";
import { ExpertiseData, SkillsData } from "../data/HomeContent";

export default function Skills() {
  return (
    <section className="aj-skills" id="expertise">
      <h2>{ExpertiseData?.title}</h2>
      <div className="grid-container">
        {React.Children.toArray(
          ExpertiseData?.expertise?.map((exp) => (
            <div className="item">
              <div className="title">
                <div className="icon">{exp?.icon}</div>
                <h3>{exp?.title}</h3>
              </div>
              <p>{exp?.details}</p>
            </div>
          ))
        )}
      </div>
      <h2>{SkillsData?.title}</h2>
      <div className="skill-grid-container">
        {React.Children.toArray(
          SkillsData?.Skills?.map((skill) => (
            <div
              className="item"
              style={{
                "--aj-skill-range": !isNaN(skill?.range)
                  ? skill?.range + "%"
                  : "80%",
              }}
            >
              <div className="title">
                <div className="icon">{skill?.icon}</div>
                <h3>{skill?.title}</h3>
              </div>
              <p>
                <span>{skill?.type}</span>
                <span>
                  {!isNaN(skill?.range) ? skill?.range + "%" : skill?.range}
                </span>
              </p>
            </div>
          ))
        )}
      </div>
    </section>
  );
}
