import React, { useEffect, useRef, useState } from "react";
import "./styles/index.scss";
import NavBar from "./layout/NavBar";
import SideImage from "./layout/SideImage";
import Socials from "./layout/Socials";
import About from "./views/About";
import Email from "./layout/Email";
import Skills from "./views/Skills";
import Experience from "./views/Experience";
import Education from "./views/Education";
import Interests from "./views/Interests";

export default function App() {
  const [Position, setPosition] = useState("#about");
  const [ShiftLayout, setShiftLayout] = useState(false);
  const AboutSectionRef = useRef(null);
  const ExpertiseSectionRef = useRef(null);
  const ExperienceSectionRef = useRef();
  const EducationSectionRef = useRef();
  const InterestsSectionRef = useRef();

  useEffect(() => {
    if (window) {
      window.addEventListener("scroll", () => {
        if (window.scrollY < AboutSectionRef.current?.clientHeight) {
          setPosition("#about");
          setShiftLayout(false);
        }
        // if (window.scrollY >= AboutSectionRef.current?.clientHeight) {
        //   setPosition("#expertise");
        //   setShiftLayout(true);
        // }
        if (window.scrollY >= ExpertiseSectionRef.current?.offsetTop - 400) {
          setPosition("#expertise");
          setShiftLayout(true);
        }
        if (window.scrollY >= ExperienceSectionRef.current?.offsetTop - 400) {
          setPosition("#experience");
          setShiftLayout(true);
        }
        if (window.scrollY >= EducationSectionRef.current?.offsetTop - 400) {
          setPosition("#education");
          setShiftLayout(true);
        }
        if (window.scrollY >= InterestsSectionRef.current?.offsetTop - 400) {
          setPosition("#interests");
          setShiftLayout(true);
        }
      });
    }
  }, []);

  console.log(Position, ShiftLayout);

  return (
    <div className="aj-body">
      <NavBar shiftLayout={ShiftLayout} active={Position} />
      <main className={ShiftLayout ? "shift" : ""}>
        <SideImage shift={ShiftLayout} />
        <div className={`aj-right-content ${ShiftLayout ? "shift" : ""}`}>
          <div className="hider"></div>
          <div ref={AboutSectionRef}>
            <About />
          </div>
          <div ref={ExpertiseSectionRef}>
            <Skills />
          </div>
          <div ref={ExperienceSectionRef}>
            <Experience />
          </div>
          <div ref={EducationSectionRef}>
            <Education />
          </div>
          <div ref={InterestsSectionRef}>
            <Interests />
          </div>
        </div>
      </main>
      <Email />
      <Socials shiftLayout={ShiftLayout} />
      <footer className="aj-footer">
        &copy;&nbsp;2024
        {new Date()?.getFullYear() > 2024
          ? ` - ${new Date().getMonth.getFullYear()} `
          : " "}
        All rights reserved | Design & Code By{" "}
        <a href="http://ahjim.com" target="_blank" rel="noopener noreferrer">
          Akhlak
        </a>
      </footer>
    </div>
  );
}
