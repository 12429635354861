import React from "react";
import { SocialMediaData } from "../data/NavsData";

export default function Socials() {
  return (
    <div className="aj-socials">
      <div className="line"></div>
      <div className="aj-socials-links">
        {React.Children.toArray(
          SocialMediaData.map((item) => (
            <a
              href={item?.href}
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              title={item?.title}
            >
              {item?.icon}
            </a>
          ))
        )}
      </div>
    </div>
  );
}
