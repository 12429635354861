import { FaTelegramPlane } from "react-icons/fa";
import {
  FaBehance,
  FaFacebookF,
  FaLinkedinIn,
  FaWhatsapp,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { MdOutlineMailOutline } from "react-icons/md";

export const EmailAddress = "hi@azshakil.com";

export const NavData = [
  {
    text: "About",
    href: "#about",
  },
  {
    text: "Expertise",
    href: "#expertise",
  },
  {
    text: "Experience",
    href: "#experience",
  },
  {
    text: "Education",
    href: "#education",
  },
  {
    text: "Interests",
    href: "#interests",
  },
];

export const SocialMediaData = [
  {
    title: "LinkedIn",
    icon: <FaLinkedinIn />,
    href: "https://www.linkedin.com/in/iazshakil/",
  },
  {
    title: "Facebook",
    icon: <FaFacebookF />,
    href: "https://fb.com/iazshakil",
  },
  {
    title: "X",
    icon: <FaXTwitter />,
    href: "https://x.com/iazshakil",
  },
  {
    title: "Telegram",
    icon: <FaTelegramPlane />,
    href: "https://t.me/iazshakil",
  },
  {
    title: "YouTube",
    icon: <FaYoutube />,
    href: "http://youtube.com/AZShakilYT",
  },
  {
    title: "WhatsApp",
    icon: <FaWhatsapp />,
    href: "https://api.whatsapp.com/send?phone=+8801757333731",
  },
  {
    title: "Behance",
    icon: <FaBehance />,
    href: "https://www.behance.net/iazshakil",
  },
  // {
  //   title: "Email",
  //   icon: <MdOutlineMailOutline />,
  //   href: "mailto:hi@azshakil.com",
  // },
];
