import { FaGraduationCap, FaMoneyBillTrendUp } from "react-icons/fa6";
import { GoLaw } from "react-icons/go";
import { GrTechnology } from "react-icons/gr";
import { HiDocumentText } from "react-icons/hi2";
import { PiCompassTool } from "react-icons/pi";
import { RiBuilding3Fill } from "react-icons/ri";
import {
  SiAdobe,
  SiAdobeillustrator,
  SiAdobephotoshop,
  SiAdobepremierepro,
  SiAutocad,
  SiAutodeskrevit,
  SiBehance,
  SiDavinciresolve,
  SiHomeadvisor,
  SiPolymerproject,
  SiRetool,
  SiSketchup,
} from "react-icons/si";
import { TbWriting } from "react-icons/tb";

export const AboutData = {
  title: "Hi, I'm AZ Shakil",
  subtitle: "Civil Engineer & Architectural Visualizer",
  details: [
    "I'm MD ASHADUZZAMAN SHAKIL well known as AZ Shakil, a highly qualified individual with a Diploma and Bachelor's degree in Civil Engineering. also working as a professional Graphics designer and Architectural Visualizer more than 8 years as of now.",
    "My biggest strength is, I am a quick self-learner. I loved to design, and structures supervision. I started as a Graphics Designer when I was at 10th grade, moving towards 3d Design and Animation. I've achieved awards for Professional Architectural Visualizer in 2017, early bird from adobe in 2019 for UI/UX. I've completed courses on Construction Project Management and Cost Estimation form USA.",
    "I have been designing from 2012, multiple brand collaboration for different local & international businesses. Also working as a Civil Engineer couple years.",
  ],
};

export const ExpertiseData = {
  title: "Expertise",
  expertise: [
    {
      title: "Software Proficiency",
      icon: <GrTechnology />,
      details:
        "Proficient in AutoCAD, SketchUP, Lumion. I have accumulated over 9 years of experience 2D Cad Drawing, and more than 8 years for 3D designs.",
    },
    {
      title: "Project Management",
      icon: <SiRetool />,
      details:
        "Expert in using MS Project and Primavera for project scheduling and management. Proven ability to manage multiple projects concurrently, ensuring timely completion and adherence to budgets and legal requirements.",
    },
    {
      title: "Architectural Visualization",
      icon: <PiCompassTool />,
      details:
        "I'm Proficient in Revit, Twinmotion and Lumion. I have expertice to create large scale landscape and animation with Lumion and Twinmotion since 2016. I've completed multiple projects inside Bangladesh and two projects in China (for thesis).",
    },
  ],
};

export const SkillsData = {
  title: "Skills",
  Skills: [
    {
      title: "AutoCAD",
      icon: <SiAutocad />,
      range: 80,
      type: "Software",
    },
    {
      title: "Sketch UP",
      icon: <SiSketchup />,
      range: 90,
      type: "Software",
    },
    {
      title: "PhotoShop",
      icon: <SiAdobephotoshop />,
      range: 85,
      type: "Software",
    },
    {
      title: "Illustrator",
      icon: <SiAdobeillustrator />,
      range: 85,
      type: "Software",
    },
    {
      title: "Premier Pro",
      icon: <SiAdobepremierepro />,
      range: 85,
      type: "Software",
    },
    {
      title: "Davinci Resolve",
      icon: <SiDavinciresolve />,
      range: 70,
      type: "Software",
    },
    {
      title: "Revit",
      icon: <SiAutodeskrevit />,
      range: 60,
      type: "Software",
    },
    {
      title: "ETABS",
      icon: <RiBuilding3Fill />,
      range: 60,
      type: "Software",
    },
    {
      title: "Safe Pro",
      icon: <GrTechnology />,
      range: "Learning...",
      type: "Software",
    },
    {
      title: "Primavera",
      icon: <GrTechnology />,
      range: "Learning...",
      type: "Software",
    },
    {
      title: "Construction Project management",
      icon: <SiRetool />,
      range: 95,
      type: "Certification",
    },
    {
      title: "Cost Estimation and control",
      icon: <FaMoneyBillTrendUp />,
      range: 80,
      type: "Certification",
    },
    {
      title: "Logo Design (Adobe)",
      icon: <SiAdobe />,
      range: 70,
      type: "Certification",
    },
    {
      title: "Architectural Visualization",
      icon: <SiHomeadvisor />,
      range: 80,
      type: "Certification",
    },
    {
      title: "PMP",
      icon: <SiPolymerproject />,
      range: "Learning...",
      type: "Certification",
    },
    {
      title: "Thesis/ Essay Writings",
      icon: <TbWriting />,
      range: 95,
      type: "Others",
    },
    {
      title: "Visa Processing",
      icon: <HiDocumentText />,
      range: 80,
      type: "Others",
    },
    {
      title: "Study Abroad Mentoring",
      icon: <FaGraduationCap />,
      range: 80,
      type: "Others",
    },
    {
      title: "Immigration Laws",
      icon: <GoLaw />,
      range: 80,
      type: "Others",
    },
  ],
};

export const ExperienceData = {
  title: "Experience",
  experiences: [
    {
      company: "Apprent",
      title: "Consultant",
      location: "China",
      from: "Aug 2021",
      to: "Present",
      details: [],
    },
    {
      company: "Freelance",
      title: "Graphics Designer and Architectural Visualizer",
      location: "Dhaka, Bangladesh",
      from: "May 2012",
      to: "Present",
      details: [],
    },
    {
      company: "Amin Construction",
      title: "Deputy Civil Engineer",
      location: "Dhaka, Bangladesh",
      from: "Dec 2023",
      to: "Jun 2024",
      details: [],
    },
    {
      company: "Northern Steel and Fabrication",
      title: "Assistant Engineer",
      location: "Dhaka, bangladesh",
      from: "Sep 2017",
      to: "May 2019",
      details: [],
    },
    {
      company: "Northern Steel and Fabrication",
      title: "Sub Assistant Engineer",
      location: "Dhaka, bangladesh",
      from: "May 2016",
      to: "Sep 2017",
      details: [],
    },
    {
      company: "Creative It Institute",
      title: "Architectural Visualize",
      location: "Dhaka, bangladesh",
      from: "Dec 2016",
      to: "May 2017",
      details: [],
    },
    {
      company: "Avila Housing Ltd.",
      title: "Civil Engineering Intern",
      location: "Dhaka, bangladesh",
      from: "Aug 2016",
      to: "Jan 2017",
      details: [],
    },
  ],
};

export const ProjectsData = {
  title: "Projects",
  projects: [
    {
      preview: "/media/projects/landscape_design.png",
      name: "Landscape Design",
      links: [
        {
          icon: <SiBehance />,
          url: "https://www.behance.net/gallery/112749205/Urban-City-Design-AZ-Shakil",
        },
      ],
      details: "",
    },
    {
      preview: "/media/projects/house_renders.jpg",
      name: "House Renders",
      links: [
        {
          icon: <SiBehance />,
          url: "https://be.net/iazshakil",
        },
      ],
      details: "",
    },
    {
      preview: "/media/projects/express_courier.jpg",
      name: "Express Courier",
      links: [
        {
          icon: <SiBehance />,
          url: "https://www.behance.net/gallery/99266111/Express-Courier-Branding",
        },
      ],
      details: "",
    },
    {
      preview: "/media/projects/Safiyya-Organic.jpg",
      name: "Safiyya-Organic",
      links: [
        {
          icon: <SiBehance />,
          url: "https://www.behance.net/gallery/118930005/Safiyya-Logo-Branding",
        },
      ],
      details: "",
    },
    {
      preview: "/media/projects/studioh.jpg",
      name: "Studio.h",
      links: [
        {
          icon: <SiBehance />,
          url: "https://www.behance.net/gallery/176449539/Polo-T-shirt-Design",
        },
      ],
      details: "",
    },
    {
      preview: "/media/projects/fashion_series.jpg",
      name: "Fashion Series",
      links: [
        {
          icon: <SiBehance />,
          url: "https://www.behance.net/gallery/131298727/Fashion-Series",
        },
      ],
      details: "",
    },
  ],
};

export const EducationData = {
  title: "Education",
  educations: [
    {
      degree: "M.Sc in Civil Engineering",
      university: "South China University of Technology",
      location: "Guangzhou, China",
      from: "2024",
      to: "2027 (Anticipated)",
      details: [],
    },
    {
      degree: "B.Sc in Civil Engineering",
      university: "Hebei University of Technology",
      location: "Tianjin, China",
      from: "2019",
      to: "2023",
      details: [],
    },
    {
      degree: "B.Sc in Civil Engineering",
      university: "Stamford University of Bangladesh",
      location: "Dhaka, Bangladesh",
      from: "2017",
      to: "2019",
      details: [],
    },
    {
      degree: "Diploma in Civil Engineering",
      university: "National Institute of Engineering & Technology",
      location: "Dhaka, bangladesh",
      from: "2012",
      to: "2016",
      details: [],
    },
  ],
};

export const InterestData = {
  title: "Interests",
  interests: [
    {
      cover:
        "https://images.unsplash.com/photo-1448375240586-882707db888b?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Traveling",
      details: [
        "I love to ride bike and traveling, although I am a rare introvert, who is shy to meet new people first time but after couple of times that new people will found me as an extrovert. meeting new people, new place knowing about their cultures ignite me.",
        "This are the flags I've seen so far: 🇨🇳 🇲🇾 🇮🇳",
      ],
    },
    {
      cover:
        "https://plus.unsplash.com/premium_photo-1663954132096-f7f968d75d44?q=80&w=2296&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Design Games",
      details: [
        "I loved to play Simcity and City Iceland to understand traffic, road management, and the formation of a city (simulation). Although Illustrator and SketchUP are my playground. Most of my time I spend on these two applications to explore more and enhance my skills.",
      ],
    },
    {
      cover:
        "https://images.unsplash.com/photo-1452421822248-d4c2b47f0c81?q=80&w=2274&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Explore",
      details: [
        "I love to spend my time researching history, geopolitics, and international politics. Along with that, I'm trying to learn every sort of research paper related to steel, RCC structures, and project management.",
      ],
    },
  ],
};
