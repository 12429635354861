import React from "react";
import { ExperienceData, ProjectsData } from "../data/HomeContent";
import { SocialMediaData } from "../data/NavsData";

export default function Experience() {
  return (
    <section className="aj-experience" id="experience">
      <h2>{ExperienceData?.title}</h2>
      {React.Children.toArray(
        ExperienceData?.experiences?.map((exp) => (
          <div className="exp">
            <p className="date">
              {exp?.from} - {exp?.to}
            </p>
            <h3 className="title">{exp?.title}</h3>
            <div className="bottom">
              <h4 className="company">{exp?.company},</h4>
              <address className="location">{exp?.location}</address>
            </div>
            {exp?.details &&
              Array.isArray(exp.details) &&
              exp.details.length > 0 && (
                <ul>
                  {React.Children.toArray(
                    exp?.details?.map((li) => <li>{li}</li>)
                  )}
                </ul>
              )}
          </div>
        ))
      )}
      <h2>{ProjectsData?.title}</h2>
      {ProjectsData?.projects &&
        Array.isArray(ProjectsData?.projects) &&
        ProjectsData?.projects.length > 0 && (
          <div className="project-grid">
            {React.Children.toArray(
              ProjectsData?.projects?.map((project) => (
                <div className="project">
                  <div className="cover">
                    <img src={project?.preview} alt={project?.name} />
                    <h3 className="title">{project?.name}</h3>
                  </div>
                  {project?.details && (
                    <div className="details">{project?.details}</div>
                  )}
                  <div className="links">
                    <div className="line"></div>
                    {project?.links &&
                      Array.isArray(project?.links) &&
                      project?.links?.length > 0 &&
                      React.Children.toArray(
                        project?.links?.map((link) => (
                          <a
                            href={link?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link?.icon}
                          </a>
                        ))
                      )}
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      <div className="cta">
        <a
          href={
            SocialMediaData?.find((el) => el.title.toLowerCase() === "behance")
              ?.href
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          See More
        </a>
      </div>
    </section>
  );
}
