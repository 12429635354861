import React, { useState } from "react";
import { NavData } from "../data/NavsData";

export default function NavBar({ shiftLayout, active }) {
  const [MobileNav, setMobileNav] = useState(false);
  return (
    <div className={`aj-navbar ${MobileNav ? "active" : ""}`}>
      <div className="container">
        <button className="mobile-nav" onClick={() => setMobileNav(!MobileNav)}>
          <span className={MobileNav ? "active" : ""}></span>
        </button>
        <nav className={MobileNav ? "active" : ""}>
          {React.Children.toArray(
            NavData.map((link) => (
              <a
                className={`link ${active === link?.href ? "active" : ""}`}
                href={link?.href}
              >
                {link?.text}
              </a>
            ))
          )}
        </nav>
        <div className="line"></div>
      </div>
    </div>
  );
}
