import React from "react";
import { InterestData } from "../data/HomeContent";

export default function Interests() {
  return (
    <section className="aj-interests" id="interests">
      <h2>{InterestData?.title}</h2>
      {InterestData?.interests &&
        Array.isArray(InterestData?.interests) &&
        InterestData.interests.length > 0 && (
          <div className="interests">
            {React.Children.toArray(
              InterestData?.interests?.map((interest) => (
                <div className="interest">
                  <div className="image">
                    <img src={interest?.cover} alt={interest?.title} />
                  </div>
                  <div className="content">
                    <h3>{interest?.title}</h3>
                    {interest?.details &&
                      Array.isArray(interest?.details) &&
                      interest.details.length > 0 &&
                      React.Children.toArray(
                        interest.details.map((line) => <p>{line}</p>)
                      )}
                  </div>
                </div>
              ))
            )}
          </div>
        )}
    </section>
  );
}
