import React from "react";
import { EducationData } from "../data/HomeContent";

export default function Education() {
  return (
    <section className="aj-education" id="education">
      <h2>{EducationData?.title}</h2>
      {React.Children.toArray(
        EducationData?.educations?.map((exp) => (
          <div className="exp">
            <p className="date">
              {exp?.from} - {exp?.to}
            </p>
            <h3 className="title">{exp?.degree}</h3>
            <div className="bottom">
              <h4 className="company">{exp?.university},</h4>
              <address className="location">{exp?.location}</address>
            </div>
            {exp?.details &&
              Array.isArray(exp.details) &&
              exp.details.length > 0 && (
                <ul>
                  {React.Children.toArray(
                    exp?.details?.map((li) => <li>{li}</li>)
                  )}
                </ul>
              )}
          </div>
        ))
      )}
    </section>
  );
}
