import React from "react";
import { AboutData } from "../data/HomeContent";

export default function About() {
  return (
    <section className="aj-about" id="about">
      <div className="text">
        <h1>{AboutData?.title}</h1>
        {AboutData?.subtitle && (
          <p className="subtitle">{AboutData?.subtitle}</p>
        )}
        {React.Children.toArray(
          AboutData?.details?.map((item) => <p className="content">{item}</p>)
        )}
      </div>
    </section>
  );
}
